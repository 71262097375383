@font-face {
    font-family: "Fakt Pro Thin";
    src: url("../assets/fonts/FaktPro-Thin.otf") format("opentype");
  }
@font-face {
    font-family: "Fakt Pro Normal";
    src: url("../assets/fonts/FaktPro-Normal.otf") format("opentype");
  }
@font-face {
    font-family: "Fakt Pro Medium";
    src: url("../assets/fonts/FaktPro-Medium.otf") format("opentype");
  }
@font-face {
    font-family: "Fakt Pro Semibold";
    src: url("../assets/fonts/FaktPro-SemiBold.otf") format("opentype");
  }
