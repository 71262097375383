// Colours
$c-medium-green: #4E5A55;
$c-light-grey: #EBECEB;
$c-tan: #CAAE9A;
$c-blue: #2B6B89;
$c-black: #333132;
$c-dark-green: #2E3A35;
$c-light-green: #7F8678;
$c-bronze: #AF8668;
$c-dark-grey: #454545;
$c-staticbtn-hover: #595C55;
$c-white: #fff;

$nav-space: 85px;

// Breakpoints
$bp-desktop-lg: 2560px;
$bp-desktop-sm: 1920px;
$bp-tablet-lg: 1366px;
$bp-tablet: 1024px;
$bp-mobile: 968px;
$bp-mobile-md: 860px;
$bp-mobile-sm: 600px;
$bp-mobile-xs: 500px;

// Fonts
$font-faktThin: "Fakt Pro Thin", sans-serif;
$font-faktNormal: "Fakt Pro Normal", sans-serif;
$font-faktMedium: "Fakt Pro Medium", sans-serif;
$font-faktSemibold: "Fakt Pro Semibold", sans-serif;

// Layout
$layout-grid-bar-width: 50px;
$layout-grid-columns: [social-bar-start] $layout-grid-bar-width [main-content-start] auto [utility-bar-start] $layout-grid-bar-width [utility-bar-end];
$layout-grid-rows: [hero-start] auto [section-start] auto [sections-end];

$z-hover-content: 2;
$z-social-bar: 15;
$z-hero-gallery: 5;
$z-hero-content: 10;
$z-hero-design: 15;
$z-section-content: 10;

// Buttons
$fs-btn: 14px;
$lh-btn: 20px;
$ls-btn: 2px;
$pd-btn: 20px;

// Nav
$fs-nav-item: 18px;
$lh-nav-item: 26px;

// Hero
$fs-hero-title: 50px;
$lh-hero-title: 60px;

$fs-hero-subtitle: 20px;
$lh-hero-subtitle: 26px;

$fs-hero-text: 24px;
$lh-hero-text: 34px;


// Static Bar/Static Buttons
$fs-staticbar-text: 14px;
$lh-staticbar-text: 20px;
$ls-staticbar-text: 2px;
$pd-staticbtn: 10px;

// List item
$fs-list: 20px;
$lh-list: 26px;

// Dropdown
$fs-section-dropdown: 12px;
$lh-section-dropdown: 16px;
$ls-section-dropdown: 2px;

// Checkbox
$fs-section-checkbox: 14px;
$lh-section-checkbox: 20px;
$ls-section-checkbox: 2px;

// Card
$fs-card-number: 76px;
$lh-card-number: 57px;
$op-card-number: 0.1;

$fs-card-header: 20px;
$lh-card-header: 39px;

$fs-card-text: 18px;
$lh-card-text: 24px;

$bs-card: 2.5px 10px 15px #70707050;

// Footer
$fs-footer-preheader: 20px;
$lh-footer-preheader: 26px;

$fs-footer-header: 30px;
$lh-footer-header: 38px;

$fs-footer-utility: 18px;
$lh-footer-utility: 24px;

// Team
$fs-join-team-header: 26px;
$lh-join-team-header: 48px;

$fs-join-team-text: 18px;
$lh-join-team-text: 24px;

// Contact
$fs-contact-header: 100px;
$lh-contact-header: 100px;
$ls-contact-header: 1px;
$op-contact-header: 0.25;

$fs-contact-cta: 18px;
$lh-contact-cta: 24px;


$fs-contact-number: 30px;
$lh-contact-number: 42px;

// Form input
$fs-form-input: 14px;
$lh-form-input: 22px;


// Spacing/Containers
$section-container-lg: 1920px;
$section-container-md: 1480px;
$section-container-sm: 1260px;

$space-content-xs: 35px;
$space-content-sm: 50px;
$space-content-md: 75px;
$space-content-lg: 100px;
$space-content-xl: 150px;

// Transitions
$transition-bg: background 0.2s ease; 
$transition-color: color 0.2s ease; 
$transition-drop-shadow: box-shadow 0.2s ease;
$transition-font: font-family 0.2s ease;
