.content__social {
    gap: 50px;
    z-index: $z-social-bar;

    &-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        width: 100%;

        position: relative;
    }

    &-link {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 40px;
        width: 40px;

        border-radius: 50px;

        @include btnTransition();


        &:hover {
            background: #FFFFFF10;
        }
    
        i {
            color: $c-light-green;
            height: 30px;
            width: auto;
            margin: 0;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-cta {
        text-transform: uppercase;
        letter-spacing: 2px;
        transform: rotate(270deg);
        width: max-content;
        margin-top: 25px;
    }
}