.bronze-bg {
    @include breakpointDown($bp-tablet) {
        padding-bottom: 50px;
    }

    h1 {
        color: $c-white !important;
    
        @include breakpointDown($bp-tablet) {
            margin-bottom: 25px;
        }
    }
}

.maintenance {
    &__intro {
        display: flex;
        flex-flow: row nowrap;

        color: $c-white;

        @include container-lg();
        margin-top: -50px;
        margin-bottom: -100px;
        
        z-index: $z-hover-content;

        @include breakpointDown($bp-tablet-lg) {
            margin: 0 50px;
            padding-bottom: 25px;

        }
        
        @include breakpointDown($bp-tablet) {
            @include contentPadding();

            flex-flow: column-reverse nowrap;
            margin: 0;
        }

        &--block {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 0 48%;
            gap: 25px 0;

            &:nth-of-type(1) {
                align-items: flex-start;
                flex-flow: column nowrap;
            }

            &:nth-of-type(2) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(3, 150);
                column-gap: 30px;

                @include breakpointDown($bp-tablet) {
                    display: none;
                }

                img {
                    max-width: 100%;

                    &:nth-child(1) {
                        grid-column: 1 / 2;
                        grid-row: 2 / 4;
                    }
                    &:nth-child(2) {
                        grid-column: 2 / 3;
                        grid-row: 1 / 3;
                    }
                }
            }

          
        }
    }
}