@mixin btnTransition {
    transition: background 0.2s ease, color 0.2s ease, box-shadow 0.2s ease, font-family 0.2s ease;
}

@mixin breakpointDown($screen) {
    @media (max-width: $screen) {
        @content;
    }
 }

@mixin breakpointUp($screen) {
    @media (min-width: $screen) {
        @content;
    }
 }

 @mixin container-md() {
    margin: auto;
    max-width: 1280px;
    width: 100%;


    @include breakpointDown($bp-tablet) {
        max-width: 790px;
        width: 100%;
    }
}

 @mixin container-lg() {
    margin: auto;
    max-width: 1500px;

    @include breakpointDown($bp-tablet) {
        max-width: 790px;
        width: 100%;
    }
}


 @mixin contentPadding() {
    padding: 0 100px;

    @include breakpointDown($bp-tablet) {
        padding: 0 75px;
    }

    @include breakpointDown($bp-mobile) {
        padding: 0 50px;
    }

    @include breakpointDown($bp-mobile-xs) {
        padding: 0 25px;
    }
}

 @mixin contentMargin() {
    margin: 0 100px;

    @include breakpointDown($bp-tablet) {
        margin: 0 75px;
    }

    @include breakpointDown($bp-mobile) {
        margin: 0 50px;
    }

    @include breakpointDown($bp-mobile-xs) {
        margin: 0 25px;
    }
}

@mixin footerBgInit() {
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
}

@mixin section-mb() {
    margin-bottom: 75px;

    @include breakpointDown($bp-mobile-md) {
        margin-bottom: 65px;
    }
}

@mixin contentBelow-sm() {
    margin-top: -5%;

    @include breakpointDown($bp-mobile-md) {
        margin-top: 5%;
    }
}

@mixin contentBelow-lg() {
    margin-top: -10%;

    @include breakpointDown($bp-mobile-md) {
        margin-top: 5%;
    }
}

@mixin hoverAboveFooter() {
    padding-top: 50px;
    margin-top: -100px;

    @include breakpointDown(1600px) {
        padding-top: 100px;
    }

    @include breakpointDown($bp-mobile-md) {
        padding-top: 0;
        margin-top: 0;
    }
}