.connectOutdoors {
    z-index: 3;
    position: relative;
    
    .showcase__card {
        flex-flow: row wrap;
    }
    .showcase__card--image {        
        background: url('../assets/images/img-maintenance-cta-2560-500.jpg');
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;

        @include breakpointDown(750px) {
            height: 300px;
            background: url('../assets/images/img-maintenance-cta-1024.jpg');
            background-position: top center;
            background-size: cover;
            background-repeat: no-repeat;
    
        }
    }
}