.cultivatingCommunities {
    @include section-mb();

    h1 {
        color: $c-white;
        padding: 0 !important;
    }

    &__title-wrapper {
        background: $c-bronze;
        padding: 100px 0;

        @include breakpointDown($bp-tablet-lg) {
            padding: 25px;
        }

        @include breakpointDown($bp-mobile) {
            padding-bottom: 100px;
        }
    }

    &__content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-end;

        gap: 25px 75px;

        z-index: $z-section-content;
        position: relative;


        @include container-md();
        @include contentPadding();

        margin-top: -100px !important;

        @include breakpointDown($bp-tablet-lg) {
            margin-top: 25px !important;
        }

        @include breakpointDown($bp-mobile) {
            margin-top: -100px !important;
            flex-flow: column nowrap;
        }

        h2 {
            color: $c-light-green;
        }


      
    }

    &__image {
        flex: 1 0 68%;

        @include breakpointDown($bp-tablet) {
            margin-top: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }


    }

    &__copy {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        align-items: flex-start;
        flex: 1 0 33%;
        // margin-top: 50px;


        gap: 25px 0;

        @include breakpointDown($bp-tablet) {
            flex: 1 0 48%;
            margin-top: 0;
        }

    }
}