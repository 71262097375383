* {
    padding: 0;
    margin: 0;
    box-sizing: border-box !important;
}

h1, h2, h3, img, span {
    user-select: none;
}

h1 {
    // padding-top: 100px;

    // @include breakpointDown($bp-tablet) {
    //     padding-top: 50px;
    // }
    // @include breakpointDown($bp-mobile-md) {
    //     padding-top: 40px;
    // }
}

body {
    background: $c-light-grey;
    color: $c-black;

    font-family: $font-faktMedium;
    position: relative;

}

a {
    color: inherit;
    text-decoration: none;
}

button {
    outline: none;
    border: none;
}

section h1 {
    
    font-weight: 100;
    padding-top: 100px;

    @include breakpointDown($bp-tablet) {
        padding-top: 50px;
    }
}

.paddingOffset-lg {
    padding-bottom: 150px !important;
}

.marginOffset-lg {
    
    margin-top: -150px !important;
}
.paddingOffset-md {
    padding-bottom: 75px !important;
}

.marginOffset-md {
    margin-top: -75px !important;
}


.team__bg-wrapper {
    background: $c-bronze;
    grid-column: 1 / 4;
    grid-row: 1 / 6;
    padding-bottom: 150px;
}

.bronze-bg {
    background: $c-bronze;
}

.noscroll {
    @include breakpointDown($bp-tablet-lg) {
        overflow: hidden;
    }
}