.contactPopup {
    input:focus-visible {
        outline: $c-bronze auto 1px;
}

#response {
    margin: 10px 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.error {
        color: #dc3545;
    }

    &.success {
        color: #28a745;
    }
}

#response.active {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

select:focus-visible {
    outline: $c-bronze auto 1px;
}

    h1, h2 {
        font-family: $font-faktThin;
    }

    h1 {
        font-size: 50px;
    }

    h2 {
        color: $c-bronze;
        font-size: 30px;
    }
    .modal__container {
        padding: 0;

        display: flex;
        flex-flow: row nowrap;

        width: 80%;
        max-width: 1200px;
    
        position: relative;
        background: $c-white;
    
    }

    .btn {
        align-self: flex-start;

        @include breakpointDown($bp-mobile) {
            align-self: center;
        }
    }

    &__image {
        flex: 1 0 600px;
        min-height: 300px;

        @include breakpointDown($bp-tablet-lg) {
           display: none;
        }
    }

    .contactPopup__mobile-cta {
        padding: 25px;
        display: none;
        justify-content: center;
        align-items: center;

        color: $c-white;
        background: $c-bronze;

        width: auto;

        @include breakpointDown($bp-mobile) {
                display: flex;
        }
    }

    &__content {
        flex: 1 0 48%;

        display: flex;
        flex-flow: column nowrap;
        // justify-content: center;
        align-items: center;

        padding: 25px;

        gap: 15px;

        text-align: center;

        min-height: 715px;

        position: relative;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            height: 100%;
            width: 100%;
        }

        @include breakpointDown($bp-tablet-lg) {
            // min-height: unset;
        }

        @include breakpointDown($bp-mobile-sm) {
           padding: 15px;
        }
    }

    &__form {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        gap: 15px;

    }


    .form-input {
        font-family: $font-faktThin;
        font-size: 14px;

        padding: 0 25px;

        color: inherit;

        flex: 1 1 48%;
        width: 100%;
        border: 1px solid $c-light-green;
        height: 50px;

        @include breakpointDown($bp-mobile) {
            padding: 0 10px;
        }

        @include breakpointDown(400px) {
            height: 30px;
        }
    }

    &__form-row {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        gap: 15px;

        &-full { 
            display: flex;
            justify-content: center;
            align-items: center;
    
            width: 100%;

            .form-input {
                flex: 1;
                height: 50px;

                @include breakpointDown(400px) {
                    height: 30px;
                }

                &:focus-visible {
                    outline: $c-bronze auto 1px;
                }
            }

            select.form-input {
                padding: 0 25px;
                border: 1px solid $c-light-green;

                @include breakpointDown($bp-mobile) {
                    padding: 0 10px;
                }

                &:focus-visible {
                    outline: $c-bronze auto 1px;
                }
            }

            textarea.form-input {
                height: 150px;
                border: none;
                resize: none;
                border: 1px solid $c-light-green;
                padding: 25px;

                @include breakpointDown($bp-mobile) {
                    padding: 10px;
                    height: 60px;
                }

                &:focus-visible {
                    outline: $c-bronze auto 1px;
                }
            }
            
            }
        }
    }

#popupClose {
    height: 25px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;

    @include breakpointDown($bp-tablet-lg) {
        position: unset;
    }

    img {
        height: 100%;
    }
}

.contactPopup__form-maintenance--service {
    display: none;
}

.aboutForm {
    transition: background-image 0.5s ease-in-out;

    .contactPopup__image {
        transition: background-image 0.5s ease-in-out;

        background: url('../assets/images/img-about-form.jpg');
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .contactPopup__form-budget {
        display: none;
    }
    
    .contactPopup__form-service {
        display: none;
    }

    .contactPopup__form-postal {
        display: none;
    }
}

.designForm {
    transition: background-image 0.5s ease-in-out;

    .contactPopup__image {
        transition: background-image 0.5s ease-in-out;

        background: url('../assets/images/img-design-and-construction-form.jpg');
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .contactPopup__form-service {
        display: none;
    }
}

.maintenanceForm {
    transition: background-image 0.5s ease-in-out;

    .contactPopup__image {
        transition: background-image 0.5s ease-in-out;

        background: url('../assets/images/img-maintenance-form.jpg');
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .contactPopup__form-budget {
        display: none;
    }

    .contactPopup__form-service {
        display: none;
    }

    .contactPopup__form-message {
        display: none;
    }

    .contactPopup__form-maintenance--service {
        display: block;
    }
}

#contactUs {
    height: min-content;
    height: 700px;
    width: 100%;
    position: relative;
    z-index: 2;
    margin-top: -25px;
}

.btnContact {
    text-align: center;
}