    .propertyLifestyleSwiper {
        display: none;
        padding-bottom: 25px;
        
        @include breakpointDown($bp-tablet) {
           display: block;
           width: 100%;
        }
    
        .swiper-slide {
            background: $c-white;
            max-width: 350px;
            // width: 100%;
        }
    
        .swiper-pagination-bullet {
            background: $c-black;
        } 
        
        .swiper-pagination-bullet-active {
            background: $c-bronze;
        }
    } 
