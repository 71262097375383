.card {
    background: $c-white;
    box-shadow: $bs-card;

    display: flex;
    flex-flow: column nowrap;

    h3 {
        font-family: $font-faktSemibold;
        line-height: 26px;

        color: $c-light-green;
    }

    &[data-card='consultation'] {
        justify-content: center;

        h3 {
            flex: 0 1 60%;
            margin-left: 25%;

            @include breakpointDown($bp-tablet-lg) {
                flex: 1 0 85%;
                margin-left: 10%;
            }
        }

        .card__content {
            padding: 10px;
            height: 225px;

            @include breakpointDown($bp-tablet) {
                padding: 10px 25px;
            }
        }

        .swiper-slide {
            background: $c-white;
            width: 100%;
        }    
    }

    &[data-card='maintenance'] {
        justify-content: flex-start;

        .card__image {
            @include breakpointDown($bp-tablet) {
                img {
                    height: 250px;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .card__content {
            padding: 25px;
            height: 250px;

            @include breakpointDown($bp-tablet-lg) {
                height: 280px;
            }

            @include breakpointDown($bp-tablet) {
                height: 250px;
            }
        }
    }

    &__image {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
            object-position: center;
        }
    }

    &__content {
        display: flex;
        flex-flow: column nowrap;
        
        .card__header {
            display: flex;
            margin-bottom: 25px;
        }

        &--step {
            .card__number {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex: 0 0 48%;
                
                font-size: 76px;
                font-family: $font-faktThin;
        
                color: $c-dark-green;
                opacity: 0.1;
            }

            .card__header {
                display: flex;
            }
        }

        
    }
}