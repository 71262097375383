.hero {
    display: flex;

    width: 100%;
    height: calc(100vh - $nav-space);
    min-height: 500px;

    background: $c-light-grey;
    color: $c-white;

    position: relative;

    margin-bottom: unset !important;

    transition: height 0.2s ease-in-out;

    @include breakpointDown($bp-tablet) {
        height: calc(600px + $nav-space);
    }

    @include breakpointDown($bp-mobile-sm) {
        flex-flow: column nowrap;
        min-height: unset;
        height: auto;
        position: unset;
        width: 100vw;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    &__content {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        gap: 25px;

        position: relative;
        z-index: $z-hero-content;

        @include container-md();
        @include contentPadding();

        @include breakpointDown($bp-mobile-sm) {
            background: $c-white;
            color: $c-black;
            padding-top: 25px;
            padding-bottom: 25px;
        }

        h1 {
            padding: unset;

            @include breakpointDown($bp-mobile-sm) {
                color: $c-bronze;
            }
        }
    }

    &__gallery {
        background: url('../assets/images/img-header-about-2560.jpg');
        background-position: center;
        background-size: cover;    

        position: absolute;
        top: 0;
        left: 0;
        z-index: $z-hero-gallery;
        
        height: 100%;
        width: 100%;

        @include breakpointDown($bp-mobile-sm) {
            height: 260px;
            position: unset;
        }


    }

    &__title, &__title--toggle {
        font-family: $font-faktSemibold;
        font-size:50px;
        line-height: 60px;

        color: $c-white;
        opacity: 1;
        text-align: start;
        text-transform: unset;
        
        max-width: 480px;

        @include breakpointDown($bp-tablet) {
            font-size:32px;
            line-height: 38px;
        }
    }

    &__title, &__subtitle {
        @include breakpointUp($bp-mobile) {
            text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.75);
        }
    }

    &__subtitle {
        font-family: $font-faktNormal;
        font-size: 24px;
        line-height: 34px;;

        max-width: 375px;

        @include breakpointDown($bp-tablet) {
            font-size: 16px;
            line-height: 24px;
    
            max-width: unset;
        }

        @include breakpointDown($bp-mobile-sm) {
            line-height: 22px;
            }
    }

    &__cta {
        transform: rotate(270deg);
    }
}

.hero[data-hero='design'] {
    background: $c-white;
    min-height: 470px;

    &.heroShrink {
        height: 470px;
    }

    @include breakpointDown($bp-mobile) {
        height: 470px;
    }

    .hero__title {
        display: none;

        @include breakpointDown(1100px) {
            width: 50%;
        }

        @include breakpointDown($bp-mobile) {
            width: 100%;
        }
    }
  
    @include breakpointDown($bp-mobile) {
        height: unset;
}

.hero__title.active {
    display: flex;
}

 .hero__gallery {
   

     &[data-hero='residential'] {
        background: url('../assets/images/img-header-residential-2560.jpg');
        background-position: contain;
        background-size: cover;    
     }

     &[data-hero='commercial'] {
        background: url('../assets/images/img-header-commercial-2560.jpg');
        background-position: contain;
        background-size: cover;    
     }

    transition: height 0.2s ease-in-out;
    transition: background-image 0.2s ease-in-out;


    @include breakpointDown($bp-mobile) {
            position: relative;
            height: 470px;
    }
 }

 .hero__content {
     margin: auto;

    @include breakpointDown($bp-mobile) {
        background: $c-white;
        color: $c-black;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    h1 {
        padding: unset;

        @include breakpointDown($bp-mobile) {
            color: $c-bronze;
        }
    }
}
}

.hero__design {
    &--options {
        z-index: calc($z-hero-design * 100);

        display: flex;
        flex-flow: column nowrap;

        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 400px;
        background: rgba(127, 134, 120, 0.5);
        backdrop-filter: blur(8px);

        @include breakpointDown($bp-mobile) {
            width: 280px;
        }
    }

    &--option {
        flex: 1 0 48%;
        padding: 40px 60px;

        position: relative;

        display: flex;
        flex-flow: column nowrap;

        opacity: 0.25;
        transition: opacity 0.2s ease-in-out; 

        &:nth-of-type(1) {
            justify-content: flex-end;
            border-bottom: 2px solid $c-white;
        } 

        &:hover {
            opacity: 0.5;
        }

        h1 {
            padding: 0;
        }

        .wrapper {
            position: absolute;
            z-index: 5000;
        }

        @include breakpointDown(1100px) {
            padding: 25px;
        }
    }

    &--option.active {
        opacity: 1;
    }
}




.heroSwiper {
    height: 100%;
    width: 100%;
    position: relative;


    .swiper-button-next {
        position: absolute;
        bottom: 50px !important;
        left: unset !important;
        top: unset !important;
        right: 0 !important;
        width: 50px;
        height: 50px;

        display: flex;
        justify-content: center;
        align-items: center;

        background: url('../assets/svg/arrow-next.svg') $c-dark-green;
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;

        @include btnTransition();

        &:hover {
            background: url('../assets/svg/arrow-next.svg') $c-light-green;
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
            }

        &::after {
            content: ""
        }
    }

    .swiper-button-prev {
        position: absolute;
        bottom: 0 !important;
        left: unset !important;
        top: unset !important;
        right: 0 !important;
        width: 50px;
        height: 50px;

        display: flex;
        justify-content: center;
        align-items: center;

        background: url('../assets/svg/arrow-prev.svg') $c-white;
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;
        @include btnTransition();

        &:hover {
            background: url('../assets/svg/arrow-prev.svg') $c-light-grey;
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
        }

        &::after {
            content: ""
        }
        
    }
}