.designContent[data-subpage='commercial'] {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.active {
        display: block;
        opacity: 1;
        
    }
}

.designContent[data-subpage='residential'] {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.active {
        display: block;
        opacity: 1;
    }
}