.about {
    &__bg-wrapper {
        background: $c-light-green;

        @include breakpointDown(1200px) {
            padding-bottom: 0 !important;
        }
    }

    color: $c-white;

    &__info {
        margin-bottom: 75px !important;
    }

    &__content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        column-gap: 25px;

        @include container-md();
        @include contentPadding();

        @include breakpointDown(1200px) {
            flex-flow: column-reverse nowrap;

            &.marginOffset-lg {
                margin-top: -125px !important;
            }
        }
    }

    &__copy {
        margin-bottom: 50px;

        h2 {
            color: $c-dark-green;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 50px;
        }

        flex: 1 1 48%;

        display: flex;
        flex-flow: column nowrap;
        flex-shrink: 1;
    }

    &__grid {
        flex: 1 0 48%;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 1fr);

        column-gap: 30px;
        row-gap: 30px;

        z-index: $z-section-content;
        position: relative;


        @include breakpointDown(1200px) {
            display: none;
        }

        &--item {
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &--item:nth-child(1) {
            grid-column: 1/2;
            grid-row: 2 / 4;
        }

        &--item:nth-child(2) {
            grid-column: 1 / 2;
            grid-row: 4 / 6;
        }

        &--item:nth-child(3) {
            grid-column:2 / 3;
            grid-row: 1 / 3;
        }

        &--item:nth-child(4) {
            grid-column:2 / 3;
            grid-row: 3 / 5;
        }
    }
    

    &__list {
        display: flex;
        flex-flow: column nowrap;

        &-item {
            font-family: $font-faktSemibold;

            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            margin-bottom: 40px;

            img {
                margin-right: 50px;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}

.content__main[data-page='team'] .about__content {
    @include breakpointUp(1200px) {
        padding-bottom: 50px;
        column-gap: 25px;
    }
}

.aboutSwiper {
    display: none;
    margin-bottom: 25px;
    padding-bottom: 25px;

    @include breakpointDown(1200px) {
        display: block;
    }

    .swiper-slide {
        max-width: 275px;
        width: 100%;
        transform: scale(0.9);
        transition: transform 0.1s ease-in-out;
    }

    .swiper-slide-active {
        transform: scale(1);
    }

    .swiper-button-next {
        color: $c-white;
    }

    .swiper-button-prev {
        color: $c-white;
    }
}