// Generic
@import "./generic/fonts.scss";
@import "./generic/variables.scss";
@import "./generic/mixins.scss";
@import "./generic/base.scss";

@import "./generic/hamburgers.scss";


@import "./components/nav.scss";

@import "./components/button.scss";
@import "./components/socialButtons.scss";
@import "./components/card.scss";
@import "./components/section.scss";
@import "./components/staticButton.scss";
@import "./components/swiper.scss";
@import "./components/layout.scss";
@import "./components/showcaseCard.scss";
@import "./components/modal.scss";
@import "./components/contactPopup.scss";

@import "./sections/hero.scss";
@import "./sections/about.scss";
@import "./sections/bookConsultation.scss";
@import "./sections/cultivatingCommunities.scss";
@import "./sections/footer.scss";

@import "./sections/transformProperty.scss";
@import "./sections/teamPhoto.scss";

@import "./sections/maintenance.scss";
@import "./sections/propertyLifestyle.scss";
@import "./sections/joinOurTeam.scss";

// Design and Construction page
@import "./sections/designPage.scss";
@import "./sections/designShowcase.scss";
@import "./sections/designGallery.scss";
@import "./sections/connectOutdoors.scss";

* {
    // outline: 1px solid red !important;
    box-sizing: border-box;
  }