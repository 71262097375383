.teamPhoto {
    margin-top: -250px;

    @include breakpointDown($bp-tablet-lg) {
        margin-top: -20%;
    }

    @include breakpointDown($bp-mobile) {
        margin-top: -150px;
    }


    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}