.showcase__card {    
    color: $c-black;

    display: flex;
    flex-flow: row wrap;

    text-align: center;

    box-shadow: $bs-card;

    @include breakpointDown($bp-mobile) {
        flex-flow: column nowrap;
    }

    .col {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        flex: 1 0 48%;
    }

    &--image {    
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;

            @include breakpointDown($bp-mobile) {
                max-height: 300px;
            }
        }

        
    }

    &--content {
        background: $c-white;

        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        padding: 50px;
        gap: 25px 0;

        @include breakpointDown($bp-tablet) {
            padding: 25px;
        }
        
        h2 {
            color: $c-light-green;
        }

        .email {
            color: $c-blue;
            font-family: $font-faktSemibold;
            text-decoration: underline;
        }
    }
}