.designShowcase {
    padding: 100px;

    @include breakpointDown($bp-tablet) {
        padding: 50px;
    }

    @include breakpointDown($bp-mobile) {
        padding: 0 0 50px 0;
    }

    &__card {
        display: flex;
        flex-flow: row nowrap;

        background: $c-light-green;
        color: $c-white;

        @include container-md();

        @include breakpointDown($bp-mobile-md) {
            flex-flow: column nowrap;
        }

        &--copy {
            background: url('../assets/svg/icon-leaf.svg');
            background-position: bottom center;
            background-repeat: no-repeat;
            flex: 0 0 430px;
            padding: 50px 50px 20% 50px;

            @include breakpointDown($bp-tablet-lg) {
                flex: 1 0 48%;
                padding: 75px 30px;
                background-size: 80%;
                background-position: bottom right;

            }

            @include breakpointDown($bp-mobile) {
                background: unset;
            }

            @include breakpointDown($bp-mobile-md) {
               padding: 30px;
               flex: 1 0 100%;

            }

            h2 {
                font-family: $font-faktSemibold;
                font-size: 30px;
                margin-bottom: 25px;
            }
        }

        &[data-showcase='residential'] {
            .designShowcase__card--image {
                background: url('../assets/images/img-residential-intro-2560.jpg');
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
    
                flex: 1 0 48%;
    
                img {
                    display: none;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
    
                @include breakpointDown($bp-tablet-lg) {
                    background: url('../assets/images/img-residential-intro-1024.jpg');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
    
                @include breakpointDown($bp-mobile-md) {
                    flex: 1 0 100%;
                    background: none;
                    height: 275px;
    
                    img {
                        display: block;
                    }
        
                }
            }
        }

        &[data-showcase='commercial'] {
            flex-flow: row-reverse nowrap;

            @include breakpointDown($bp-mobile-md) {
                flex-flow: column nowrap;
            }

            .designShowcase__card--image {
                background: url('../assets/images/img-commercial-intro-2560.jpg');
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
    
                flex: 1 0 48%;
    
                img {
                    display: none;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
    
                @include breakpointDown($bp-tablet-lg) {
                    background: url('../assets/images/img-commercial-intro-1024.jpg');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
    
                @include breakpointDown($bp-mobile-md) {
                    flex: 1 0 100%;
                    background: none;
                    height: 275px;
    
                    img {
                        display: block;
                    }
        
                }
            }
        }
    }
}

.backyardEscapeSwiper {
    display: none;
    padding-bottom: 25px;
    
    @include breakpointDown($bp-tablet) {
       display: block;
       width: 100%;
    }

    .swiper-slide {
        background: $c-white;
        max-width: 275px;
        width: 100%;
    }

    .swiper-pagination-bullet {
        background: $c-black;
    } 
    
    .swiper-pagination-bullet-active {
        background: $c-bronze;
    }
} 