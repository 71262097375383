

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    font-family: $font-faktSemibold;
    font-size: $fs-btn;
    letter-spacing: $ls-btn;
    padding: $pd-btn;
    text-transform: uppercase;

    min-width: 250px;
    height: 57px;

    @include btnTransition();

    &Careers {
        text-align: center;
        min-width: max-content;
    }

    &__gold {
        background: $c-bronze;
        color: $c-white;

        &:hover {
            background: $c-light-green;
            font-family: $font-faktSemibold;

        }
    }

    &__green {
        font-family: $font-faktSemibold;

        background: $c-light-green;
        color: $c-white;

        &:hover {
            background: $c-bronze;
            font-family: $font-faktSemibold;
        }
    }

    &__white {
        font-family: $font-faktSemibold;
        background: $c-white;
        color: $c-bronze;

        &:hover {
            font-family: $font-faktSemibold;

            background: $c-light-green;
            color: $c-white;
        }
    }
}