.staticBtn {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    background: $c-black;
    color: $c-light-green;

    font-family: $font-faktSemibold;
    font-size: $fs-staticbar-text;
    letter-spacing: $ls-staticbar-text;

    text-transform: uppercase;

    padding: $pd-staticbtn;

    width: 100%;
    flex: 1 0 48%;

    @include btnTransition();

    &:hover {
        background: $c-staticbtn-hover;
        color: $c-white;
    }

    span {
        transform: rotate(270deg);

    }

    &__active {
        background: $c-light-green;
        color: $c-white;
    }
}