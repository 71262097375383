.swiper-button-next { color: $c-bronze; }
.swiper-button-prev  {color: $c-bronze; }
.swiper-pagination-bullet,
.swiper-pagination-bullet-active { background: $c-white; }

.swiper-pagination {
    bottom: 0 !important;
}



.swiper-slide img {
    display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
}

.swiper-slide {
    opacity: 0.5;
    transition: 0.5s opacity;
}

.swiper-slide-active {
    opacity: 1;
}