.bookConsultation {
    @include section-mb();

    h2 {
        color: $c-light-green;
    }

    &__content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        gap: 30px;

        z-index: $z-section-content;
        position: relative;
    
        margin-bottom: 25px !important;

        @include container-lg();

        @include breakpointDown(1600px) {
            flex-flow: row wrap;
            @include contentPadding();
            margin-top: 25px;
        }

        .copy {
            flex: 1 0 23%;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            row-gap: 10px;


            @include breakpointDown(1600px) {
                flex: 1 0 100%;
            }
        }

        .card-container {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            flex: 1 0 73%;
            gap: 30px;


            @include breakpointDown($bp-tablet) {
                display: none;
                }

                .card {
                flex: 1 0 30%;                
            }
        }
    }
}

.content__main[data-page='maintenance'] .bookConsultation {
    margin-top: 150px;

    .bookConsultation__content {
        padding: 0 50px !important;
    }

    @include breakpointDown($bp-tablet-lg) {
        margin-top: 50px;
 
     }

    @include breakpointDown($bp-tablet) {
        margin-top: 0;
 
     }


}

.content__main[data-page='design'] .bookConsultation {
    .bronze-bg {
        padding-bottom: 150px;

        @include breakpointDown($bp-tablet) {
            padding-bottom: 0;
        }
    }

    .bookConsultation__content {
        margin-top: -150px;

        @include breakpointDown(1600px) {            
            .copy {
                h2, p {
                    color: $c-white !important;
                }
    
            }

            .btn__gold {
                background: $c-light-green;
                text-align: center;

                &:hover {
                    background: $c-white;
                    color: $c-bronze;
                }
            }
        }

        @include breakpointDown($bp-tablet) {
            background: $c-bronze;
            margin-top: 0;
            max-width: unset;
            padding-bottom: 25px;

            

 
        }
    }
}


.consultationSwiper {
    display: none;
    padding-bottom: 25px;
    overflow: hidden;
    
    @include breakpointDown($bp-tablet) {
       display: block;
       width: 100%;
    }

    .swiper-slide {
        background: $c-white;
        max-width: 275px;
    }

    .swiper-pagination-bullet {
        background: $c-black;
    } 
    
    .swiper-pagination-bullet-active {
        background: $c-bronze;
    }
}

.content__main[data-page='design'] .bookConsultation .bookConsultation__content {
    @include breakpointDown($bp-tablet-lg) {
        padding: 0 25px;
    }

    @include breakpointDown($bp-tablet) {
        @include contentPadding();
    }
}