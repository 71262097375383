.footer {
    background-color: $c-white;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    
    min-height: 725px;

    z-index: $z-hero-gallery;
    @include footerBgInit();

    @include breakpointDown($bp-desktop-sm) {
        @include footerBgInit();
    }

    @include breakpointDown(1600px) {
        min-height: 550px;
    }

    @include breakpointDown($bp-tablet) {
        @include footerBgInit();
        min-height: 400px;
    }

    @include breakpointDown($bp-mobile) {
        min-height: 325px;
    }

    @include breakpointDown(750px) {

        background-image: unset;

        display: flex;
        flex-flow: column nowrap;
    }
    
    @include breakpointUp(750px) {
        @include contentPadding();
    }
    
    &__mobile-image {
        display: none;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        
        @include breakpointDown(750px) {
            display: flex;
            justify-content: center;
        }
    }

    &__content {
        z-index: $z-hero-content;

        @include container-md();

        padding-top: 40px !important;
        padding-bottom: 40px !important;

        @include breakpointDown(750px) {
            @include contentPadding();
        }
    
        .row {
            display: flex;
            flex-flow: column nowrap;
            row-gap: 10px;
        }
    }

    &__content--container {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        row-gap: 25px;

        &__preheader {
            color: $c-dark-green;
            font-size: 20px;
            line-height: 26px;
        }

        h2 {
            color: $c-light-green;
            font-size: 30px;
            line-height: 38px;

            font-family: "Fakt Pro Semibold", sans-serif;
            
            @include breakpointDown($bp-mobile-md) {
                font-size: 26px;
                line-height: 32px;
                }
        }

        &__email {
            color: $c-light-green;
            font-size: 30px;
            line-height: 38px;

        }
    }

    &__utility {
        display: block;

        @include breakpointDown(750px) {
            display: none !important;
        }

        &-mobile {
            display: none;
            flex-flow: column nowrap;
            justify-content: flex-end;
            align-items: center;
            
            color: $c-white;

            @include breakpointDown(750px) {
                display: flex;
                width: 100%;
                height: 300px;
                padding: 10px;
                background-image: linear-gradient(0, black, transparent 30%);
            }
        }
    }

        
        &--desktop-lg {
            display: block;

            @include breakpointDown($bp-desktop-sm) {
                display: none;
            }
        }

        &--desktop-sm {
            display: none;

            @include breakpointDown($bp-desktop-sm) {
                display: block;
            }

            @include breakpointDown($bp-tablet-lg) {
                display: none;
            }
        }

        &--tablet {
            display: none;

            @include breakpointDown($bp-tablet-lg) {
                display: block;
            }

            @include breakpointDown(750px) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @include breakpointDown(750px) {
                display: block;
            }
        }
    }

.content__main[data-page="index"] .footer {
    // background-image: url('../assets/images/bg-about-footer-2560.jpg');
    @include footerBgInit();

    @include breakpointDown($bp-desktop-sm) {
        // background-image: url('../assets/images/bg-about-footer-1920.jpg');
        @include footerBgInit();
    }

    @include breakpointDown($bp-tablet-lg) {
        // background-image: url('../assets/images/bg-about-footer-1024.jpg');
        @include footerBgInit();
    }

    @include breakpointDown(750px) {
        background-image: unset;

        &__mobile-image {
            // background-image: url('../assets/images/bg-about-footer-500.jpg');
            background-position: top right;
            background-size: cover;
            background-repeat: no-repeat;
            height: 300px;
        }
    }

}

.content__main[data-page="team"] .footer {
    @include contentBelow-sm();
    @include hoverAboveFooter();
    
    // background-image: url('../assets/images/bg-team-footer-2560.jpg');
    @include footerBgInit();

    @include breakpointDown($bp-desktop-sm) {
        // background-image: url('../assets/images/bg-team-footer-1920.jpg');
        @include footerBgInit();
    }

    @include breakpointDown($bp-tablet-lg) {
        // background-image: url('../assets/images/bg-team-footer-1024.jpg');
        @include footerBgInit();
    }

    @include breakpointDown(750px) {
        background-image: unset;

        &__mobile-image {
            // background-image: url('../assets/images/bg-team-footer-500.jpg');
            background-position: top right;
            background-size: cover;
            background-repeat: no-repeat;
            height: 300px;
        }
    }
}

.content__main[data-page="maintenance"] .footer {
    @include contentBelow-sm();

    // background-image: url('../assets/images/bg-maintenance-footer-2560.jpg');
    @include footerBgInit();
    @include hoverAboveFooter();



    @include breakpointDown($bp-desktop-sm) {
        // background-image: url('../assets/images/bg-maintenance-footer-1920.jpg');
        @include footerBgInit();
    }

    @include breakpointDown($bp-tablet-lg) {
        // background-image: url('../assets/images/bg-maintenance-footer-1024.jpg');
        @include footerBgInit();
    }

    @include breakpointDown(750px) {
        padding-top:0;
        background-image: unset;

        &__mobile-image {
            // background-image: url('../assets/images/bg-maintenance-footer-500.jpg');
            background-position: top right;
            background-size: cover;
            background-repeat: no-repeat;
            height: 300px;
        }
    }
}

.designContent[data-subpage='residential'] .footer {
    // background-image: url('../assets/images/bg-residential-footer-2560.jpg');
    @include footerBgInit();
    @include hoverAboveFooter();

    @include breakpointDown($bp-desktop-sm) {
        // background-image: url('../assets/images/bg-residential-footer-1920.jpg');
        @include footerBgInit();
    }

    @include breakpointDown($bp-tablet-lg) {
        // background-image: url('../assets/images/bg-residential-footer-1024.jpg');
        @include footerBgInit();
    }

    @include breakpointDown(750px) {
        background-image: unset;

        &__mobile-image {
            // background-image: url('../assets/images/bg-residential-footer-500.jpg');
            background-position: top right;
            background-size: cover;
            background-repeat: no-repeat;
            height: 300px;
        }
    }

}

.designContent[data-subpage='commercial'] .footer {
    // background-image: url('../assets/images/bg-commercial-footer-2560.jpg');
    @include footerBgInit();
    @include hoverAboveFooter();

    @include breakpointDown($bp-desktop-sm) {
        // background-image: url('../assets/images/bg-commercial-footer-1920.jpg');
        @include footerBgInit();
    }

    @include breakpointDown($bp-tablet-lg) {
        // background-image: url('../assets/images/bg-commercial-footer-1024.jpg');
        @include footerBgInit();
    }

    @include breakpointDown(750px) {
        background-image: unset;

        &__mobile-image {
            // background-image: url('../assets/images/bg-commercial-footer-500.jpg');
            background-position: top right;
            background-size: cover;
            background-repeat: no-repeat;
            height: 300px;
        }
    }

}

