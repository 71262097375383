$z-nav-controls-mobile: 50;
$z-nav-content-mobile: 40;

@keyframes fromLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


#navSpace {
    height: $nav-space;
}

.nav {
    background: $c-black;
    color: $c-white;

    display: flex;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;



    @include breakpointUp($bp-tablet-lg) {
        height: $nav-space;
    }

    @include breakpointDown($bp-tablet-lg) {
        background: none;
    }

    &__wrapper {
        max-width: 2560px;
        margin: auto;
    
    }

    &__controls {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        
        

        @include breakpointDown($bp-tablet-lg) {
            background: $c-black;

            flex-flow: column nowrap;
            width: 100%;
            height: $nav-space;

            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            z-index: $z-nav-controls-mobile;

            padding: 0 50px;
        }

        @include breakpointDown($bp-mobile-md) {
            padding: 0 25px;
        }

        &-tablet {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 0 25px;

            .btn {
                display: none;

                @include breakpointDown($bp-tablet-lg) {
                    display: block;
                }

                @include breakpointDown($bp-mobile-md) {
                    display: none;
                }
            }
        }

        #navToggle {
            display: none;
            fill: $c-white;
            padding: 0;

            @include breakpointDown($bp-tablet-lg) {
                display: flex;
            }
        }
    }

    &__logo img {                
        @include breakpointDown($bp-mobile) {
            max-width: 200px;
        }
    }

    &__container {
        max-width: $bp-desktop-sm;
        width: 100%;
        margin: auto;
        margin: 0 50px;

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        transition: background-color 0.2s ease-in-out;

        @include breakpointDown($bp-tablet-lg) {
            flex-flow: column nowrap;
            margin: 0;

            background: url('../assets/images/img-menu.jpg') $c-black;
            background-position: top right;
            background-size: contain;
            background-repeat: no-repeat;

            transition: background-color 0.2s ease-in-out;

        }

        @include breakpointDown(1100px) {
            transition: background-color 0.2s ease-in-out;

            background: $c-black;
        }
    }

    &__links {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        height: 100%;

        @include breakpointDown($bp-tablet-lg) {
            flex-flow: column nowrap;
            align-items: flex-start;

            width: 100%;
            margin: 0 50px;
            height: unset;
        }

        .btn {
            margin-left: 2.5%;

            @include breakpointDown($bp-tablet-lg) {
                display: none;
                margin-left: unset;
                justify-content: flex-start
            }

            @include breakpointDown($bp-mobile-md) {
                display: block;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;

        text-align: center;

        height: 100%;
        padding: 0 25px;

        color: $c-bronze;

        transition: border-bottom 0.2s ease-in-out;
        transition: color 0.2s ease-in-out;

        @include breakpointUp($bp-tablet-lg) {
            border-bottom: 2px solid $c-black;
        }

        @include breakpointDown($bp-tablet-lg) {
            font-size: 35px;
            margin-left: unset;
            text-align: start;
            padding: 0;
            margin-bottom: 25px;
        }

        &:hover {
            color: $c-tan;
            
            @include breakpointUp($bp-tablet-lg) {
                border-bottom: 2px solid rgba(255, 255, 255, 0.8);
                transition: border-bottom 0.2s ease-in-out;
                transition: color 0.2s ease-in-out;    
            }
        }
          
        p {
            display: block;
            min-width: max-content;

            @include breakpointDown($bp-mobile-md) {
                min-width: auto;
            }
        }

        &.active {
            color: $c-white;

            @include breakpointUp($bp-tablet-lg) {
                border-bottom: 2px solid $c-white;
            }
        }
    }

    &__mobile-wrapper {
        display: none;

        @include breakpointDown($bp-tablet-lg) {
            display: flex;
            flex-flow: column nowrap;
            margin: 25px 0;
        }
    }

    &__mobile-socials {
        display: flex;
        flex-flow: row nowrap;
        margin-left: -15px;
    }
}


.nav__content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    transition: background-color 1s ease-in-out;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;

    @include breakpointDown($bp-tablet-lg) {
        background: transparent;

        display: none;
        flex-flow: column nowrap;
        border-top: 1px solid $c-bronze;

        animation: fromLeft 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

        height: 100vh;
        width: 100%;
        padding: 50px;
        max-width: 650px;

        transition: opacity 0.5s ease-in-out;
        transition: max-width 0.5s ease-in-out;

        opacity: 0;

        z-index: $z-nav-content-mobile;

        @include breakpointDown(1100px) {
            max-width: unset;
            transition: max-width 0.5s ease-in-out;
         }

        @include breakpointDown($bp-mobile-md) {
            max-width: unset;
            transition: max-width 0.5s ease-in-out;
        }

        &.active {
            background: $c-black;
            display: flex;
            align-self: flex-start;
            opacity: 1;

        
        }
    }
}

