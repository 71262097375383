.transformProperty {
    display: flex;
    justify-content: center;
    
    .showcase__card {
        z-index: 50;


        @include breakpointUp($bp-tablet-lg) {

   
        }

    }


    @include container-lg();

    padding: 0 50px 50px 50px !important;

    @include breakpointDown($bp-tablet) {
        // margin-top: -20%;
    }
}