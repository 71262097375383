section {
    display: flex;
    flex-flow: column nowrap;
    max-width: 100%;

    h1 {
        font-family: $font-faktThin;
       font-size: 150px;
       line-height: 150px;
    
       color: $c-dark-green;
       opacity: 0.1;
        
       text-transform: uppercase;
       text-align: center;

        margin: 0;

       @include breakpointDown ($bp-desktop-sm) {
           font-size: 125px;
           line-height: 130px;
       }

       @include breakpointDown ($bp-tablet) {
           font-size: 80px;
           line-height: 80px;
       }

       @include breakpointDown ($bp-mobile) {
           font-size: 70px;
           line-height: 75px;
       }

       @include breakpointDown ($bp-mobile-sm) {
           font-size: 55px;
           line-height: 65px;
       }

       @include breakpointDown ($bp-mobile-xs) {
           font-size: 50px;
           line-height: 55px;
       }
    }
    
    h2 {
        font-family: $font-faktSemibold;
        font-size: 30px;
        line-height: 38px;

        @include breakpointDown ($bp-tablet) {
            font-size: 26px;
            line-height: 32px;
        }
 
    }

    p {
        font-family: $font-faktNormal;
        font-size: 18px;
        line-height: 24px;

        @include breakpointDown ($bp-tablet) {
            font-size: 16px;
            line-height: 22px;
        }
    }
}