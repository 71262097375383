.designGallery {
    @include container-lg();
    @include section-mb();

    @include breakpointDown($bp-tablet-lg) {
        max-width: unset !important;
    }

    &__container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;

        @include breakpointDown($bp-tablet) {
            flex-flow: column nowrap;
        }

        @include breakpointDown($bp-tablet) {
            position: relative;
        }
    }

    &__content {
        flex: 1 1 68%;
        max-width: 1000px;

        @include breakpointDown(1500px) {
            flex: 0 1 60%;
            max-width: 60%;
        }

        @include breakpointDown($bp-tablet) {
            flex: 1 0 425px;
            max-width: unset;
        }
    }

    &__menu {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        padding: 0 50px 0 30px;
        row-gap: 10px;
        flex: 1 1 28%;
        
        p {
            user-select: none;
        }

        @include breakpointDown(1500px) {
            flex: 1 1 48%;
        }

        @include breakpointDown($bp-tablet) {
            padding: 25px;
        }

        @include breakpointDown($bp-tablet) {
            background: $c-white;
            position: absolute;
            top: 5px;
            left: 5px;
            z-index: 49;
            padding: 0;
            width: 275px;
            box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
            row-gap: 0;

        }

        #menuToggle {
            padding: 10px;
            background: $c-white;
        }

        .mobile-menu-label {
            display: none;
            padding: 0;

            @include breakpointDown($bp-tablet) {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
            }

            p {
                font-family: $font-faktSemibold;
            }

            img {
                height: 10px;
            }
        }

        .mobile-menu-wrapper {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            transition: height 0.2s ease-in-out;

            @include breakpointDown($bp-tablet) {
                height: 0;
                display: none;
                transform: translateY(-100px);
            }
        }
        
    }

    .designGallery__menu.active {
        .mobile-menu-wrapper {
            @include breakpointDown($bp-tablet) {
                transform: translateY(0);
                height: auto;
                display: block;
            }
        }
    }

    &__item {
        font-family: $font-faktNormal;
        text-transform: uppercase;

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        transition: background 0.2s ease-in-out;
        transition: color 0.2s ease-in-out;

        @include breakpointDown($bp-tablet) {
            padding: 10px;

            &:hover {
                background: $c-light-green;
                color: $c-white;
            }
         }

        img {
            height: 60px;
            width: auto;
            padding: 5px;

            @include breakpointDown($bp-tablet) {
               display: none;
            }
        }

        p {
            text-align: start;
            flex: 1 1;
            margin: 0 25px;

            font-size: 14px;
            letter-spacing: 2px;


            @include breakpointDown($bp-tablet) {
                font-size: 12px;
                letter-spacing: 2px;
                margin: 0 25px 0 0;
            }
        }

        &--all {
            border-top: 1px solid $c-light-grey;
            border-bottom: 1px solid $c-light-grey;
            padding: 10px 0;

                @include breakpointDown($bp-tablet) {
                    padding: 10px;
                }
        }
    }

    &__checkbox {
        height: 30px;
        width: 30px;
                
        @include breakpointDown($bp-tablet) {
            height: 15px;
            width: 15px;
            }
    }

    &__checkbox:checked {
        accent-color: $c-bronze !important;
    }
}

.designSwiper {
    position: relative;
    height: 100%;
    width: 100%;

    
    .swiper-slide {
        height: 100%;
        width: 100%;
        opacity: 1;
        transition: 0.25s opacity;
        
    
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .swiper-slide.hidden {
        display: none !important;
        opacity: 0 !important;
    }

    .swiper-button-next {
        position: absolute;
        bottom: 50px !important;
        left: unset !important;
        top: unset !important;
        right: 0 !important;
        width: 50px;
        height: 50px;

        display: flex;
        justify-content: center;
        align-items: center;

        background: url('../assets/svg/arrow-next.svg') $c-dark-green;
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;

        @include btnTransition();

        &:hover {
            background: url('../assets/svg/arrow-next.svg') $c-light-green;
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
            }

        &::after {
            content: ""
        }
    }

    .swiper-button-prev {
        position: absolute;
        bottom: 0 !important;
        left: unset !important;
        top: unset !important;
        right: 0 !important;
        width: 50px;
        height: 50px;

        display: flex;
        justify-content: center;
        align-items: center;

        background: url('../assets/svg/arrow-prev.svg') $c-white;
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;
        @include btnTransition();

        &:hover {
            background: url('../assets/svg/arrow-prev.svg') $c-light-grey;
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
        }

        &::after {
            content: ""
        }
        
    }
}