.content {
    display: grid;
    grid-template-columns: 50px auto 50px;
    background: $c-white;

    
    @include breakpointDown($bp-mobile) {
        display: block;
    }

    &__social {
       grid-column: 1 / 2;

        background: $c-black;
        color: $c-white;

        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        width: 50px;


        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;

        &-container {
            align-items: center;
        }

        @include breakpointDown($bp-mobile) {
            display: none;
            width: 0;
        }
    }
    

    &__main {
        grid-column: 2 / 3;
        overflow-x: hidden;

        @include breakpointDown($bp-mobile) {
            width: 100%;
        }
    
    }

    &__utility {
        width: 50px;

        grid-column: 3 / 4;
        
        background: $c-black;
        color: $c-white;

        @include breakpointDown($bp-mobile) {
            display: none;
            width: 0;

        }
    }
}

.container-lg {
    max-width: 1640px;
    margin: auto;

    @include breakpointDown($bp-desktop-sm) {
        flex: 1;
        max-width: 1500px;
    }

    @include breakpointDown($bp-tablet-lg) {
        max-width: 1000px;
        flex: 1;
    }

    @include breakpointDown($bp-tablet) {
        flex: 1;
        max-width: 790px;
    }
}